import React, { Component } from "react";
import "../css/Breaker.css";

import Logo from "../images/logo_3.png";

export class Breaker extends Component {
    render() {

        return (
            <div id="breaker" className="Breaker flex flexColumn">
                <div className="p-breaker flex flexRow">
                    <div className="breaker-icons flex flexRow">
                        <div className="icons-inner flex flexColumn">
                            <img className="icons-img" src={process.env.PUBLIC_URL + "/wind.png"} alt="" />
                            <p className="icons-p">Cool in Summer</p>
                        </div>
                        <div className="icons-inner flex flexColumn">
                            <img className="icons-img" src={process.env.PUBLIC_URL + "/temp.png"} alt="" />
                            <p className="icons-p">Warm in Winter</p>
                        </div>
                        <div className="icons-inner flex flexColumn">
                            <img className="icons-img" src={process.env.PUBLIC_URL + "/wallet.png"} alt="" />
                            <p className="icons-p">Wallet Friendly</p>
                        </div>
                        <div className="icons-inner flex flexColumn">
                            <img className="icons-img" src={process.env.PUBLIC_URL + "/leaf.png"} alt="" />
                            <p className="icons-p">Energy Efficient</p>
                        </div>
                    </div>
                    <div className="breaker-img-box">
                        <img src={Logo} alt="" />
                    </div>
                    <div className="breaker-icons flex flexRow">
                        <div className="icons-inner flex flexColumn">
                            <img className="icons-img" src={process.env.PUBLIC_URL + "/lock.png"} alt="" />
                            <p className="icons-p">Extremely Secure</p>
                        </div>
                        <div className="icons-inner flex flexColumn">
                            <img className="icons-img" src={process.env.PUBLIC_URL + "/maintenance.png"} alt="" />
                            <p className="icons-p">Maintenance Free</p>
                        </div>
                        <div className="icons-inner flex flexColumn">
                            <img className="icons-img" src={process.env.PUBLIC_URL + "/mute.png"} alt="" />
                            <p className="icons-p">No Unwanted Noise</p>
                        </div>
                        <div className="icons-inner flex flexColumn">
                            <img className="icons-img" src={process.env.PUBLIC_URL + "/water.png"} alt="" />
                            <p className="icons-p">No Condensation</p>
                        </div>
                    </div>
                </div>
                <div className="p-bottom flex flexRow">
                    <p>In Australia, passive houses are becoming more popular as awareness of energy efficiency and sustainability grows. They are especially beneficial in extreme climates, helping to keep homes comfortable and energy bills low regardless of whether you’re in a hot, cold, or temperate region. By adhering to the Passive House Standard, Australian homes can achieve high levels of comfort and efficiency while contributing to a more sustainable future</p>
                    <img src={process.env.PUBLIC_URL + "/house-2.png"} alt="" />
                </div>
            </div>
        );
    }
}

export default Breaker;
