import React, { useState } from "react";
import "../css/QuoteForm.css";

async function sendEmail(data) {
  try {
    const response = await fetch("https://api.legendarywindows.com.au/api/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      alert("Email sent successfully!");
    } else {
      alert("Failed to send email.");
    }
  } catch (error) {
    console.error("Error:", error);
    alert("An error occurred while sending the email.");
  }
}

function QuoteForm() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    location: "",
    projectType: "",
    details: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value.trimStart(), // Trim leading whitespace
    }));
  };

  const validateForm = () => {
    const trimmedData = {};
    for (const key in formData) {
      trimmedData[key] = formData[key].trim(); // Trim all whitespace
      if (trimmedData[key] === "" && key !== "location") {
        alert(`${key} cannot be empty.`);
        return false;
      }
    }
    return trimmedData;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedData = validateForm();
    if (trimmedData) {
      sendEmail(trimmedData);
    }
  };

  return (
    <form className="quote-form" onSubmit={handleSubmit}>
      <div className="quote-form-inner flex flexColumn">
        <p>
          Name<span className="required">*</span>
        </p>
        <input
          className="form-input-small"
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <p>
          Mobile Number<span className="required">*</span>
        </p>
        <input
          className="form-input-small"
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />

        <p>
          Email<span className="required">*</span>
        </p>
        <input
          className="form-input-small"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />

        <p>Project Location</p>
        <input
          className="form-input-small"
          type="text"
          name="location"
          value={formData.location}
          onChange={handleChange}
        />

        <p>
          New Build or Renovation<span className="required">*</span>
        </p>
        <div className="flex flexRow">
          <input
            type="radio"
            id="newBuild"
            name="projectType"
            value="New Build"
            checked={formData.projectType === "New Build"}
            onChange={handleChange}
            required
          />
          <label for="html">New Build</label>
        </div>
        <div className="flex flexRow">
          <input
            type="radio"
            id="renovation"
            name="projectType"
            value="Renovation"
            checked={formData.projectType === "Renovation"}
            onChange={handleChange}
          />
          <label for="css">Renovation</label>
        </div>

        <p>
          Tell us more about your project<span className="required">*</span>
        </p>
        <textarea
          className="form-textarea"
          id="details"
          name="details"
          value={formData.details}
          onChange={handleChange}
          required
          rows="4" // Sets the default height of the textarea (number of lines).
          cols="50" // Sets the default width of the textarea.
          placeholder="Enter detailed information about the project here..."
          style={{ resize: "none" }}
        ></textarea>

        <input className="form-button" type="submit" value="Send" />
      </div>
    </form>
  );
}

export default QuoteForm;
