import React, { Component } from "react";
import Slider from "./Slider.js"
import "../css/Recents.css"


export class Recents extends Component {
    render() {

        return (
            <div id="recent" className="Recents flex flexColumn">
                <h1 className="recent-title">Our Recent Works</h1>
                <Slider />
            </div>
        );
    }
}

export default Recents;
