import React, { Component } from "react";
import "../css/Doors.css";

export class Doors extends Component {
    render() {

        return (
            <div id="doors" className="Doors flex flexColumn">
                <div className="doors-start-box flex flexColumn">
                    <h3 className="windows-title-first doors-title">Discover the Ideal Double Glazed Doors for Your Home</h3>
                    <p>At Legendary Windows, we believe that every home deserves doors that are as functional as they are beautiful. That’s why we offer a wide array of double glazed doors, tailored to complement your style and meet your specific needs. Whether you’re looking to save space, enhance your home's aesthetics, or improve energy efficiency, our range has something perfect for you</p>
                </div>
                <div className="doors-features flex flexRow">
                    <div className="doors-features-inner flex flexColumn">
                        <h5>Sliding & Stacker Doors</h5>
                        <p>Designed for areas where saving space is essential while maintaining a modern look</p>
                    </div>
                    <div className="doors-features-inner flex flexColumn">
                        <h5>French Doors</h5>
                        <p>Bring a touch of sophistication and luxury into your home with our elegant designs</p>
                    </div>
                    <div className="doors-features-inner flex flexColumn">
                        <h5>Lift and Slide Doors</h5>
                        <p>Create an uninterrupted view with large glass panels that offer a seamless connection to the outdoors</p>
                    </div>
                    <div className="doors-features-inner flex flexColumn">
                        <h5>Bi-Fold Doors</h5>
                        <p>Ideal for merging your indoor and outdoor spaces, offering flexibility and style</p>
                    </div>
                    <div className="doors-features-inner flex flexColumn">
                        <h5>Tilt and Slide Doors</h5>
                        <p>Enjoy the functionality of sliding doors combined with the versatility of tilt windows for added ventilation</p>
                    </div>
                    <div className="doors-features-inner flex flexColumn">
                        <h5>Casement Doors</h5>
                        <p>Perfect for those seeking a balance of superior thermal performance and ease of use</p>
                    </div>
                    <div className="doors-features-inner flex flexColumn">
                        <h5>Front and Back Doors</h5>
                        <p>Choose from a range of secure, energy-efficient doors that enhance both the look and safety of your home</p>
                    </div>
                    <div className="doors-features-inner flex flexColumn">
                        <h5>Alfresco & Patio Doors</h5>
                        <p>Transform your outdoor area into a comfortable, functional living space that can be enjoyed year-round</p>
                    </div>
                </div>
                <div className="doors-benefits flex flexColumn">
                    <div className="doors-benefits-inner flex flexRow">
                        <div className="doors-img-box flex flexColumn">
                            <div className="doors-img-inner flex flexColumn">
                                <h5>Timeless Elegance</h5>
                                <p>Our doors add a sophisticated touch to any home, with designs that blend seamlessly with various architectural styles</p>
                            </div>
                            <img style={{ objectPosition: "50% 30%" }} src={process.env.PUBLIC_URL + "/landhouse.png"} alt="" />
                        </div>
                    </div>

                    <div className="doors-benefits-inner flex flexRow">
                        <div className="doors-img-box flex flexColumn">
                            <div className="doors-img-inner flex flexColumn">
                                <h5>Durability with Minimal Maintenance</h5>
                                <p>Made from robust uPVC, our doors resist fading, cracking, and warping, ensuring they stay beautiful and require little upkeep over time</p>
                            </div>
                            <img src={process.env.PUBLIC_URL + "/house-2.png"} alt="" />
                        </div>
                    </div>

                    <div className="doors-benefits-inner flex flexRow">
                        <div className="doors-img-box flex flexColumn">
                            <div className="doors-img-inner flex flexColumn">
                                <h5>Energy Efficiency</h5>
                                <p>Our doors are engineered for superior insulation, keeping your home warm in winter and cool in summer, leading to lower energy bills</p>
                            </div>
                            <img src={process.env.PUBLIC_URL + "/landhouse2.png"} alt="" />
                        </div>
                    </div>

                    <div className="doors-benefits-inner flex flexRow">
                        <div className="doors-img-box flex flexColumn">
                            <div className="doors-img-inner flex flexColumn">
                                <h5>Superior Noise Reduction</h5>
                                <p>Enjoy a quieter home environment with doors that block out unwanted external noise, perfect for homes in busy areas</p>
                            </div>
                            <img src={process.env.PUBLIC_URL + "/landhouse3.png"} alt="" />
                        </div>
                    </div>


                    <div className="doors-benefits-inner flex flexRow">
                        <div className="doors-img-box flex flexColumn">
                            <div className="doors-img-inner flex flexColumn">
                                <h5>Enhanced Security</h5>
                                <p>Featuring advanced multi-locking systems, our doors provide top-notch security, ensuring your home and family are well-protected</p>
                            </div>
                            <img style={{ objectPosition: "50% 65%" }} src={process.env.PUBLIC_URL + "/landhouse5.png"} alt="" />
                        </div>
                    </div>
                </div>
                <div className="doors-last flex flexColumn">
                    <h5>Legendary Windows - Your Premier Choice for Double Glazed Doors</h5>
                    <p>When you choose Legendary Windows, you're choosing a commitment to quality, craftsmanship, and customer satisfaction. Our double glazed uPVC doors are locally manufactured using premium materials, including top-tier German hardware, and are designed with the latest technological advancements in mind. Each door comes with a 10-year warranty, giving you confidence in your investment.
                    </p>
                    <p className="doors-last-p">Ready to elevate your home with the best double glazed doors on the market? Contact the expert team at Legendary Windows today to discuss your needs and arrange a free, no-obligation quote. Let us help you transform your home with doors that combine style, security, and energy efficiency.</p>
                </div>
            </div>
        );
    }
}

export default Doors;
