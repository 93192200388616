import React, { Component } from "react";
import "../css/Navbar.css";
import Logo from "../images/logo_2.png";

import closeImg from "../images/close.png";
import menuImg from "../images/menu.png";

export class Navbar extends Component {
  state = {
    isMenuOpen: false,
  };

  toggleMenu = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
    if (this.state.isMenuOpen) {
      document.body.style.overflow = "visible";
    } else {
      document.body.style.overflow = "hidden"
    }
  };

  render() {
    return (
      <div className="Navbar flex flexRow">
        <div className="flex flexRow">
          <img className="navbar-logo" src={Logo} alt="" />
          {/* <div className="nav-title">Legendary Windows</div> */}
        </div>

        <div className="nav-routes flex flexRow">
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <a href="#recent">Recent Works</a>
          <a className="nav-contact" href="#contact">
            Contact
          </a>
        </div>

        {/*  MOBILE DEVICES  */}
        <button className="menu-toggle" onClick={this.toggleMenu}>
          <img className="menu-img" src={menuImg} alt="" />
        </button>

        <div
          className={`fullscreen-menu ${this.state.isMenuOpen ? "open" : "closed"
            }`}
        >
          <img className="menu-logo" src={Logo} alt="" />

          <button className="close-menu" onClick={this.toggleMenu}>
            <img className="close-img" src={closeImg} alt="" />
          </button>
          <a href="#home" onClick={this.toggleMenu}>
            Home
          </a>
          <a href="#about" onClick={this.toggleMenu}>
            About
          </a>
          <a href="#recent" onClick={this.toggleMenu}>
            Recent Works
          </a>
          <a href="#contact" onClick={this.toggleMenu}>
            Contact
          </a>
        </div>
      </div>
    );
  }
}

export default Navbar;
