import React, { useRef, useState } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const ShowcaseSwiper = () => {
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };


    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="flex flexRow swiperGrid">
                        <img className='swiper-img' src={process.env.PUBLIC_URL + `/windows-7.jpg`} alt="" />
                        <img className='swiper-img' src={process.env.PUBLIC_URL + `/windows-8.jpg`} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="flex flexRow swiperGrid">
                        <img className='swiper-img' src={process.env.PUBLIC_URL + `/windows-1.png`} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="flex flexRow swiperGrid">
                        <img className='swiper-img' src={process.env.PUBLIC_URL + `/windows-9.jpg`} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="flex flexRow swiperGrid">
                        <img className='swiper-img' src={process.env.PUBLIC_URL + `/windows-3.jpg`} alt="" />
                        <img className='swiper-img' src={process.env.PUBLIC_URL + `/windows-4.jpg`} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="flex flexRow swiperGrid">
                        <img className='swiper-img' src={process.env.PUBLIC_URL + `/windows-5.jpg`} alt="" />
                        <img className='swiper-img' src={process.env.PUBLIC_URL + `/windows-6.jpg`} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="flex flexRow swiperGrid">
                        <img className='swiper-img' src={process.env.PUBLIC_URL + `/windows-2.png`} alt="" />
                    </div>
                </SwiperSlide>
                <div className="autoplay-progress" slot="container-end">
                    <svg viewBox="0 0 48 48" ref={progressCircle}>
                        <circle cx="24" cy="24" r="20"></circle>
                    </svg>
                    <span ref={progressContent}></span>
                </div>
            </Swiper>
        </>
    );
}


export default ShowcaseSwiper;