import React, { Component } from "react";
import "../css/PassiveHouse.css";

export class PassiveHouse extends Component {
    render() {

        return (
            <div id="passivehouse" className="PassiveHouse flex flexColumn">
                <img className="p-wave" src={process.env.PUBLIC_URL + "wave.png"} alt="" />
                <h3 className="windows-title-first passive-title">Passive House</h3>
                <p>A passive house in Australia is a type of energy-efficient building designed to maintain comfortable indoor temperatures year-round while using minimal energy for heating and cooling. This concept is based on the Passive House Standard, which originated in Germany and has been adapted for various climates, including Australia's diverse environments.</p>
                <div className="passive-house-features flex flexRow">
                    <div className="p-h-feature-box flex flexColumn">
                        <h5>High Insulation</h5>
                        <p>Passive houses are built with high levels of insulation to minimize heat loss in winter and heat gain in summer. This involves using thick walls, high-quality insulation materials, and well-insulated windows and doors</p>
                    </div>
                    <div className="p-h-feature-box flex flexColumn">
                        <h5>Airtight Construction</h5>
                        <p>These homes are constructed to be highly airtight, reducing drafts and preventing warm or cool air from escaping. This is achieved through careful sealing of all joints and openings in the building envelope</p>
                    </div>
                    <div className="p-h-feature-box flex flexColumn">
                        <h5>Energy-Efficient Windows</h5>
                        <p>Passive houses use high-performance, double or triple-glazed windows with low-emissivity coatings and argon gas fills to improve thermal performance. In Australia, this often means using uPVC or aluminium-clad windows that meet strict energy efficiency criteria</p>
                    </div>
                    <div className="p-h-feature-box flex flexColumn">
                        <h5>Mechanical Ventilation with Heat Recovery</h5>
                        <p>To ensure good indoor air quality while maintaining energy efficiency, passive houses use a mechanical ventilation system with heat recovery. This system exchanges stale indoor air with fresh outdoor air while transferring heat between the incoming and outgoing air streams, reducing the need for additional heating or cooling</p>
                    </div>
                    <div className="p-h-feature-box flex flexColumn">
                        <h5>Passive Solar Design</h5>
                        <p>The design of a passive house incorporates passive solar principles, such as optimizing window placement to capture solar energy in winter and shading to prevent overheating in summer. This helps regulate indoor temperatures naturally</p>
                    </div>
                    <div className="p-h-feature-box flex flexColumn">
                        <h5>Thermal Bridge-Free Design</h5>
                        <p>The construction avoids thermal bridges, which are areas where heat can bypass insulation and cause energy loss. This ensures that the building envelope performs uniformly</p>
                    </div>
                    {/* <div className="p-h-feature-box flex flexColumn">
                        <h5>Reduced Energy Consumption</h5>
                        <p>Due to these design features, a passive house typically requires up to 90% less energy for heating and cooling compared to conventional homes. This makes it extremely cost-effective and environmentally friendly</p>
                    </div> */}
                </div>
                <img className="p-wave-2" src={process.env.PUBLIC_URL + "wave-2.png"} alt="" />
            </div>
        );
    }
}

export default PassiveHouse;
