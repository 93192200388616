import React, { Component } from "react";

import "../css/Slider.css";

import Marquee from "react-marquee-slider";
import times from "lodash/times";

import slider1 from "../photos/slider1.png";
import slider2 from "../photos/slider2.png";
import slider3 from "../photos/slider3.png";
import slider4 from "../photos/windows-1.png";
import slider5 from "../photos/windows-2.png";


export class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      velocity: 60,
    };
  }

  componentDidMount() {
    this.updateVelocityBasedOnWidth();
    window.addEventListener("resize", this.updateVelocityBasedOnWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateVelocityBasedOnWidth);
  }

  updateVelocityBasedOnWidth = () => {
    const width = window.innerWidth;
    let newVelocity;
    if (width < 561) {
      newVelocity = 30;
    } else if (width >= 561 && width < 1001) {
      newVelocity = 40;
    } else {
      newVelocity = 60;
    }

    this.setState({ velocity: newVelocity });
  };

  render() {
    const { velocity } = this.state;
    const photos = [slider1, slider4, slider2, slider5, slider3];

    return (
      <div className="slider-container">
        <Marquee velocity={velocity}>
          {times(5, Number).map((id) => (
            <div
              key={`marquee-example-item-${id}`}
              className="carousel-cont"
              style={{
                width: "50rem",
                marginRight: "0px",
                display: "inline-block",
              }}
            >
              <img
                className="carousel-img"
                src={photos[id]}
                alt={`Item ${id + 1}`}
              />
            </div>
          ))}
        </Marquee>
      </div>
    );
  }
}

export default Slider;
