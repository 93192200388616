import React, { Component } from "react";

import "../css/Footer.css"
import Logo from "../images/logo.png"

export class Footer extends Component {
    render() {
        let year = new Date().getFullYear()

        return (
            <div className="Footer flex flexRow">
                <img className="footer-logo" src={Logo} alt="" />
                <p className="footer-text">Copyright @{year} Legendary Windows. All rights reserved.</p>
            </div>
        );
    }
}

export default Footer;
