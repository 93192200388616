import React, { Fragment, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Navbar from "./components/Navbar.js";
import Home from "./components/Home.js";
import About from "./components/About.js";
import Recents from "./components/Recents.js"
import Contact from "./components/Contact.js"
import Footer from "./components/Footer.js"
import Windows from "./components/Windows.js"
import Doors from "./components/Doors.js"

import "./App.css";
import "./css/responsive.css"
import PassiveHouse from "./components/PassiveHouse.js";
import Breaker from "./components/Breaker.js";


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <>
            <Fragment>
              <Navbar />
              <Home />
              <About />
              <Windows />
              <Doors />
              <PassiveHouse />
              <Breaker />
              <Recents />
              <Contact />
              <Footer />
            </Fragment>
          </>
        } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
