import React, { Component } from "react";
import "../css/About.css";

export class About extends Component {
    render() {

        return (
            <div id="about" className="About">
                <div className="about-intro flex flexColumn">
                    <h1 className="about-title">About Us</h1>
                    <p className="about-desc">Pvc windows is what I do. This is what I know, at the end of the day I have been in this trade for over 30 years. We do new homes, renovation jobs such as homes or offices. I have done it all, commercial buildings such as cafes and restaurants & offices in the city. To residential homes local or not, it doesn’t matter. Even though we are based in Melbourne we have done jobs in Sydney, Adelaide, even Perth.
                        <br />
                        <br />
                        UPVC provides a lot of benefits to our customers, as they are an appropriate product in many applications whether it be residential homes or commercial properties. Our Products are made with the highest quality PVC Produced in Belgin, Deceunick, the top PVC window and door producers in the world. Aswell as Double-Glazed windows manufactured here in Australia, this combination produces:
                    </p>
                </div>
                <div className="about-features flex flexRow">
                    <div className="about-feature-box flex flexColumn">
                        <img src="/security_symbol.png" className="feature-box-img" alt="Legendary Windows Security" />
                        <h4 className="feature-box-title">Extreme Security</h4>
                        <p className="feature-box-desc">Reinforced locks and toughened glass for unmatched home protection</p>
                    </div>
                    <div className="about-feature-box flex flexColumn">
                        <img src="/noise_symbol.png" className="feature-box-img" alt="Legendary Windows Noise Cancellation" />
                        <h4 className="feature-box-title">Noise Cancellation</h4>
                        <p className="feature-box-desc">Block out noise with our superior soundproofing technology</p>
                    </div>
                    <div className="about-feature-box flex flexColumn">
                        <img src="/flex_symbol.png" className="feature-box-img" alt="Legendary Windows Flexibility" />
                        <h4 className="feature-box-title">Aesthetic Flexibility</h4>
                        <p className="feature-box-desc">Customizable designs to complement any home style</p>
                    </div>
                    <div className="about-feature-box flex flexColumn">
                        <img src="/maintenance_symbol.png" className="feature-box-img" alt="Legendary Windows Low Maintenance" />
                        <h4 className="feature-box-title">Low Maintenance</h4>
                        <p className="feature-box-desc">Effortless upkeep with durable and long-lasting materials</p>
                    </div>
                    <div className="about-feature-box flex flexColumn">
                        <img src="/eco_symbol.png" className="feature-box-img" alt="Legendary Windows Eco" />
                        <h4 className="feature-box-title">Eco-Friendly</h4>
                        <p className="feature-box-desc">Sustainable and recyclable, for an environmentally conscious choice</p>
                    </div>
                    <div className="about-feature-box flex flexColumn">
                        <img src="/resistance_symbol.png" className="feature-box-img" alt="Legendary Windows Resistant" />
                        <h4 className="feature-box-title">Weather Resistant</h4>
                        <p className="feature-box-desc">Designed to endure, whatever the weather</p>
                    </div>
                    <div className="about-feature-box flex flexColumn">
                        <img src="/energy_symbol.png" className="feature-box-img" alt="Legendary Windows Efficient" />
                        <h4 className="feature-box-title">Energy Efficiency</h4>
                        <p className="feature-box-desc">Keep your home comfortable and your energy bills low</p>
                    </div>
                    <div className="about-feature-box flex flexColumn">
                        <img src="/insulation_symbol.png" className="feature-box-img" alt="Legendary Windows Insulation" />
                        <h4 className="feature-box-title">Superior Insulation</h4>
                        <p className="feature-box-desc">Optimal thermal regulation for comfort in all seasons</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;
