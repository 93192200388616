import React, { Component } from "react";
import "../css/Home.css";

export class Home extends Component {
    render() {

        return (
            <div className="Home flex">
                <div class="background-overlay"></div>
                <div class="background-darken"></div>
                <div className="home-cont-upper flex flexRow">
                    <div className="home-cont flex flexColumn">
                        <h1 className="home-title"><span className="home-title-first">UPVC</span><br />WINDOWS <span className="downlighted">&</span> DOORS</h1>
                        <p className="home-desc">This is our specialty. European inspired PVC and double-glazed windows provide the best energy efficient, quality home for you. Manufactured here in Australia, we strive to always provide the best for our customers. With over 30 years of experience all we can promise is the best quality service, best products, and the best results.</p>
                        <a className="home-quote" href="#contact">Get a Quote</a>
                    </div>
                    <div className="home-links flex flexColumn">
                        <a className="home-scroll-link" href="#windows">Windows</a>
                        <a className="home-scroll-link" href="#doors">Doors</a>
                        <a className="home-scroll-link" href="#passivehouse">Passive House</a>
                    </div>
                </div>

                <div className='landing-scroll flex flexRow'>
                    <video
                        className="scrolldown"
                        src={`${process.env.PUBLIC_URL}/scrolldown-animation.webm`}
                        autoPlay
                        loop
                        muted
                    />
                    <p
                        className='see-more'
                    >
                        SEE MORE
                    </p>
                    <video
                        className="scrolldown scrolldown-right"
                        src={`${process.env.PUBLIC_URL}/scrolldown-animation.webm`}
                        autoPlay
                        loop
                        muted
                    />
                </div>
            </div>
        );
    }
}

export default Home;
