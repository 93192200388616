import React, { Component } from "react";
import "../css/Contact.css"

import QuoteForm from "./QuoteForm";

export class Recents extends Component {
    render() {

        return (
            <div id="contact" className="Contact flex flexColumn">
                <h1 className="contact-title">Contact</h1>
                <div className="contact-inner flex flexRow">
                    <div className="quote flex flexColumn">
                        <h3 className="quote-title">Claim Your Free Quote</h3>
                        <QuoteForm />
                    </div>
                    <div className="contact-info flex flexColumn">
                        <div className="contact-inner-2-box flex flexColumn">
                            <img className="contact-2-img" src="/addressicon.png" alt="" />
                            <h4 className="contact-2-title">Address</h4>
                            <p className="contact-2-small">40 Quarter Street Roxburgh Park 3064 Melb, VIC</p>
                        </div>
                        <div className="contact-inner-2-box flex flexColumn">
                            <img className="contact-2-img" src="/callicon.png" alt="" />
                            <h4 className="contact-2-title">Call us</h4>
                            <p className="contact-2-small">0414322009</p>
                        </div>
                        <div className="contact-inner-2-box flex flexColumn">
                            <img className="contact-2-img" src="/emailicon.png" alt="" />
                            <h4 className="contact-2-title">Email us</h4>
                            <p className="contact-2-small">legendarywindows35@gmail.com</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Recents;
