import React, { Component } from "react";
import "../css/Windows.css";
import Swiper from "./Swiper";

export class Windows extends Component {
    render() {

        return (
            <div id="windows" className="Windows flex flexColumn">
                <div className="windows-initial flex flexColumn">
                    <h3 className="windows-title-first">More about UPVC Windows</h3>
                    <p className="windows-desc-first">uPVC windows are a modern and practical choice for your home, made from a type of durable plastic known as unplasticized polyvinyl chloride. These windows are strong and can withstand various weather conditions, such as rain and sunlight, making them a long-lasting option. Unlike traditional wooden windows, which require regular painting and care, uPVC windows are easy to maintain — they only need an occasional clean with soap and water.</p>
                </div>
                <div className="windows-second flex flexRow">
                    <p className="w-second-f">One of the major advantages of uPVC windows is their energy efficiency. They provide excellent insulation, helping to keep your home warm in the winter and cool in the summer, which can lead to lower energy bills. Additionally, uPVC windows tend to be a little pricier than wooden or aluminium windows but offer cost savings over time due to their low maintenance needs and durability.</p>
                    <p className="w-second-s">uPVC windows also contribute to home security with robust locking systems and help reduce outside noise, making your living environment more peaceful. Available in a variety of styles and colours, uPVC windows can be chosen to match the aesthetic of your home. In summary, uPVC windows combine durability, easy upkeep, energy efficiency, and versatility, making them a smart and attractive option for many homeowners.</p>
                </div>
                <div className="windows-third flex flexColumn">
                    <div className="flex flexRow">
                        <div className="windows-third-inner flex flexColumn">
                            <div>
                                <p className="w-side-main">Our windows are crafted to offer both top-notch energy efficiency and a sleek, modern look. Our high-quality uPVC double-glazed windows blend excellent design with superior performance. They meet all Australian standards and come with a comprehensive 10-year warranty. Available in a variety of colours and glass options, these windows ensure a stylish finish.</p>
                                <p className="w-side-title">Designed to fit seamlessly with various building types</p>
                                <div className="w-side flex flexRow">
                                    <p className="w-side-f"><span className="w-line">-</span>Including render, blockwork, precast panels, and lightweight constructions</p>
                                    <p className="w-side-s"><span className="w-line">-</span>Our uPVC windows and doors also adapt to different state conditions across Australia</p>
                                </div>
                            </div>
                            <p className="w-third-last">Whether you're in Sydney, Brisbane, Adelaide, Perth, Tasmania, Melbourne, or anywhere in between, we have solutions tailored to your needs.</p>
                        </div>
                        <div className="windows-third-inner-img">
                            <Swiper />
                        </div>
                    </div>
                    <p className="w-img-bottom">“At Legendary Windows, we pride ourselves on offering uPVC double-glazed windows that are lead-free, UV-stabilized, and compliant with the latest Australian standards, including bushfire regulations up to BAL 29 and BAL 40.”</p>
                </div>
            </div>
        );
    }
}

export default Windows;
